import {
	IonButton,
	IonModal,
	IonText,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonButtons,
	IonContent,
	IonGrid,
	IonRow,
	IonCol,
} from '@ionic/react'

import { useState } from 'react'
import { ImageLoader } from '../ImageLoader'
import { Icon } from '@iconify/react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/zoom'
import { Zoom } from 'swiper/modules'
import CustomVimeoPlayer from '../CustomVimeoPlayer'

const fileTypeIcon = (file) => {
	const type = file.split('.').pop()
	if (type === 'pdf') {
		return 'vscode-icons:file-type-pdf2'
	} else if (type === 'doc' || type === 'docx') {
		return 'vscode-icons:file-type-word'
	} else if (type === 'xls' || type === 'xlsx') {
		return 'vscode-icons:file-type-excel'
	} else if (type === 'ppt' || type === 'pptx') {
		return 'vscode-icons:file-type-powerpoint2'
	} else {
		return 'vscode-icons:default-folder'
	}
}

export const PostMultimedia = ({
	postGroup,
	only_post,
	autoplay = false,
	isWatching = null,
	setHasPlayed = () => {},
}) => {
	const [isOpen, setIsOpen] = useState(false)
	const images = postGroup.post.images ? JSON.parse(postGroup.post.images) : []
	const videos = postGroup.post.videos ? postGroup.post.videos : []
	const files = postGroup.post.files ? JSON.parse(postGroup.post.files) : []
	const storage_path = postGroup.post.storage_path
	const post_id = postGroup.id
	const mediaQuantity = images.length + videos.length + files.length
	const needsModal = mediaQuantity > 1
	const layout = {
		video: videos.length,
		image: images.length && !videos.length,
		files: !images.length && !videos.length && files.length,
		images: images.length >= 3,
	}

	const openModal = () => {
		setIsOpen(true)
	}

	const Overlay = () => {
		return (
			<OverlayImages
				number={mediaQuantity - 1}
				onClickFunction={openModal}
			/>
		)
	}

	return (
		<div className="multimedia-in-post-page">
			{needsModal && (
				<GroupMultimediaModal
					isOpen={isOpen}
					setIsOpen={setIsOpen}
					storage_path={storage_path}
					images={images}
					videos={videos}
					files={files}
					post_id={postGroup.id}
				/>
			)}

			{!!layout.video && (
				<CustomVimeoPlayer
					isWatching={isWatching}
					autoplay={autoplay}
					video={videos[0]}
					post_id={post_id}
					only_post={only_post}
					setHasPlayed={setHasPlayed}
				/>
			)}
			<div className="multimedia-post-container">
				{mediaQuantity > 1 && <Overlay />}
				{!!layout.image && (
					<Swiper
						modules={[Zoom]}
						zoom={true}>
						<SwiperSlide>
							<ImageLoader
								image={storage_path + images[0]}
								zoom={true}
							/>
						</SwiperSlide>
					</Swiper>
				)}
				{!!layout.files && (
					<div
						style={{
							display: 'flex',
							height: '300px',
							position: 'relative',
							width: '300px',
						}}>
						{files.length === 1 ? (
							<a
								href={storage_path + files[0]}
								rel="noopener noreferrer"
								style={{
									width: '100%',
									textAlign: 'center',
									display: 'inline-block',
									textDecoration: 'none',
								}}
								target="_blank">
								<IonButton color="light">
									<Icon
										icon={fileTypeIcon(files[0])}
										class="ion-margin-end"
										width="30"
									/>
									Scarica ora
								</IonButton>
							</a>
						) : (
							<Icon
								icon="vscode-icons:default-folder"
								width="100%"
							/>
						)}
					</div>
				)}
				{!!layout.images && (
					<IonGrid>
						<IonRow>
							{images.map(
								(image, key) =>
									key > 0 &&
									key <= 3 && (
										<IonCol key={key}>
											<ImageLoader
												image={storage_path + image}
												css="post-extra-image"
											/>
										</IonCol>
									)
							)}
						</IonRow>
					</IonGrid>
				)}
			</div>
		</div>
	)
}

const OverlayImages = ({ number = 1, onClickFunction = () => {} }) => {
	return (
		<div
			onClick={onClickFunction}
			style={{
				height: '100%',
				width: '100%',
				backgroundColor: 'rgba(0, 0, 0, 0.4)',
				position: 'absolute',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				textAlign: 'center',
				fontSize: '3rem',
				color: 'white',
				fontWeight: 'var(--font-size-black)',
				zIndex: 2,
			}}>
			<IonText>+ {number}</IonText>
		</div>
	)
}

const GroupMultimediaModal = ({
	isOpen = false,
	setIsOpen = () => {},
	images = null,
	videos = null,
	files = null,
	post_id = 1,
	storage_path = null,
}) => {
	const closeModal = () => {
		setIsOpen(false)
	}

	return (
		<IonModal
			isOpen={isOpen}
			onDidDismiss={closeModal}
			canDismiss={true}
			initialBreakpoint={1}
			breakpoints={[0, 0.25, 0.5, 0.75, 1]}>
			<IonHeader>
				<IonToolbar>
					<IonTitle>Multimedia</IonTitle>
					<IonButtons slot="end">
						<IonButton onClick={closeModal}>Chiudi</IonButton>
					</IonButtons>
				</IonToolbar>
			</IonHeader>
			<IonContent className="post-modal ion-padding">
				{videos &&
					videos.map((video) => (
						<div
							style={{ margin: '1rem 0.5rem' }}
							key={`vimeo-container${video}-${post_id}`}>
							<CustomVimeoPlayer
								video={video}
								post_id={post_id}
								isOpen={isOpen}
								overlay={true}
							/>
						</div>
					))}
				{images &&
					images.map((image) => (
						<Swiper
							key={image}
							modules={[Zoom]}
							zoom={true}>
							<SwiperSlide>
								<ImageLoader
									image={storage_path + image}
									css="post-image ion-margin-vertical"
									zoom={true}
								/>
							</SwiperSlide>
						</Swiper>
					))}
				{files &&
					files.map((file, key) => (
						<a
							key={key}
							href={storage_path + file}
							rel="noopener noreferrer"
							width="100%"
							style={{
								width: '100%',
								textAlign: 'center',
								display: 'inline-block',
								textDecoration: 'none',
							}}
							target="_blank">
							<IonButton color="light">
								<Icon
									icon={fileTypeIcon(file)}
									class="ion-margin-end"
									width="30"
								/>
								Scarica ora
							</IonButton>
						</a>
					))}
			</IonContent>
		</IonModal>
	)
}
